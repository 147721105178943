import { styled } from "@material-ui/core";
import { FormButton } from "components/Global/Form";
import Container from "components/MerchantCentre/Container";
import Content from "components/MerchantCentre/Content";
import Search from "components/MerchantCentre/MerchantConsole/Products/Add/Search/SearchCategory";
import { useDispatch, useSelector } from "react-redux";
import { setRedirect } from "service/actions/site";
import { createSelector } from "reselect";
import { useState } from "react";
import { createProduct } from "service/actions/merchant";

const Row = styled("div")(({ theme, bottomline, align = "center" }) => ({
    padding: 22,
    display: "flex",
    flexDirection: "column",
    gap: 20,
    alignItems: align,
    borderBottom: `${bottomline}px solid ${theme.yoho.md_color.grey[400]}`,
}));

const Title = styled("div")(({ theme, bottomline, align = "center" }) => ({
    display: "flex",
    flexDirection: "column",
    gap: 10,
    alignItems: align,
    borderBottom: `${bottomline}px solid ${theme.yoho.md_color.grey[400]}`,
    marginTop: 30,
    "& p": {
        margin: 0,
        "&.title-1": {
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "36px",
            lineHeight: "49px",
        },
        "&.title-2": {
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "22px",
            lineHeight: "32px",
        },
    },
}));

const Filter = styled("div")({
    display: "flex",
    gap: 20,
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    "& > div:nth-child(2)": {
        alignSelf: "flex-start",
        marginTop: "20px",
    },
});

const $filteredCategories = createSelector(
    (state) => state.merchant.product.config?.categories,
    (state) => state.merchant.product.config?.subcategories,
    (state) => state.merchant.product.config?.subsubcategories,
    (_, search) => search,
    (_, search, mode) => mode,
    (categories, subcategories, subsubcategories, search, mode) => {
        switch (mode) {
            case 1:
                return search
                    ? categories.filter((item) => item.name.toLowerCase().includes(search.toLowerCase()))
                    : categories;
            case 2:
                return subcategories;
            case 3:
                return subsubcategories;
        }
    },
);

const SelectProduct = () => {
    const dispatch = useDispatch();
    const [search, setSearch] = useState("");
    const [mode, setMode] = useState(1);
    const categories = useSelector((state) => $filteredCategories(state, search, mode));
    const draftCategory = useSelector((state) => state.merchant.product.draft);
    const productName = useSelector((state) => state.merchant.product.add.data.spu.vital_info_product_name_chinese);

    const formatLabelWithData = (catInfos) => {
        return catInfos
            ? catInfos?.map((info) => {
                  const { zh_tw_name = "", name, ...rest } = info;

                  if (zh_tw_name) {
                      return { name: `${name} (${zh_tw_name})`, ...rest };
                  }
                  return info;
              })
            : [];
    };
    return (
        <Container>
            <div className="main search-product">
                <Content>
                    <Title>
                        <p className="title-1">Select a product category for your item</p>
                        <p className="title-2">{productName}</p>
                    </Title>
                    <Row align="strecth">
                        <Filter>
                            <Search
                                placeholder="Search for a category"
                                data={formatLabelWithData(categories)}
                                action={setSearch}
                                mode={mode}
                                setMode={setMode}
                            />
                        </Filter>
                    </Row>
                    <Row>
                        <FormButton
                            cvariant="contained"
                            disabled={!draftCategory.category?.hasOwnProperty(3) || mode !== 3}
                            onClick={() => dispatch(createProduct())}
                        >
                            Continue to create new
                        </FormButton>
                        <FormButton cvariant="text" onClick={() => dispatch(setRedirect("/products/add"))}>
                            back to start
                        </FormButton>
                    </Row>
                </Content>
            </div>
        </Container>
    );
};

export default SelectProduct;
