import { useState } from "react";
import { DialogContent, DialogTitle, styled, TextField } from "@material-ui/core";
import { FormButton, FormCategory } from "components/Global";
import Popup from "components/Global/Layout/popup";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { bulkUpdateEditProductDraft } from "service/actions/merchant";
import { unsetPopup } from "service/actions/site";
import { Alert } from "@material-ui/lab";

const CustomDialogTitle = styled(DialogTitle)(({ theme }) => ({
    ...theme.yoho.typography.h4,
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    "& p": {
        margin: 0,
    },
}));

const CustomDenyDialogContent = styled(DialogContent)(({ theme }) => ({
    borderTop: `1px solid ${theme.yoho.md_color.grey[300]}`,
}));

const CustomFormButton = styled(FormButton)({
    alignSelf: "center",
    marginBottom: "10px",
});

const validateFields = (formData) => {
    try {
        if (
            formData.variations_sales_market_price <= 0 ||
            formData.variations_sales_member_price <= 0 ||
            formData.variations_sales_vip_price <= 0
        ) {
            throw { tab: "sales", message: "Prices must be more than zero." };
        }

        if (formData.variations_sales_member_price > formData.variations_sales_market_price) {
            throw { tab: "sales", message: "Member price cannot be more than market price." };
        }

        if (formData.variations_sales_vip_price >= formData.variations_sales_member_price) {
            throw { tab: "sales", message: "VIP price must be less than member price or left empty." };
        }

        if (
            formData.variations_sales_member_price < formData.variations_sales_market_price * 0.2 ||
            formData.variations_sales_vip_price < formData.variations_sales_market_price * 0.2
        ) {
            throw {
                tab: "sales",
                message: "Member price or VIP price is less than 20% of market price. Confirm to proceed?",
                error: false,
                warning: true,
            };
        }

        return { error: false };
    } catch (error) {
        return {
            error: true,
            ...error,
        };
    }
};

const VariationsBulkActionForm = ({ action, close, title = "Bulk Edit Variations" }) => {
    const { register, handleSubmit } = useForm();

    const [errorMsg, setErrorMsg] = useState("");
    const dispatch = useDispatch();
    const selectedGoodIds = useSelector((state) => state.merchant.product.checkbox_ids.edit_goods_ids);

    const onSubmit = (data) => {
        const { error, tab, message, warning } = validateFields(data);
        if (error) {
            setErrorMsg(message);
            return;
        }

        if (warning) {
            const proceed = window.confirm(message);
            if (!proceed) return;
        }

        dispatch(
            bulkUpdateEditProductDraft({
                checkedIds: selectedGoodIds,
                updatePayload: data,
            }),
        );
        dispatch(unsetPopup());
    };

    return (
        <Popup size="md" fullWidth>
            <CustomDialogTitle>{title}</CustomDialogTitle>
            <CustomDenyDialogContent>
                {errorMsg && (
                    <Alert severity="error" style={{ margin: "15px 20px 0px" }}>
                        {errorMsg}
                    </Alert>
                )}
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormCategory justifycontent="center">
                        <p style={{ width: "100%", margin: "0px" }}>
                            {
                                "Leave blank for fields you are not bulk editing (Market Price > Member Price > VIP Price)"
                            }
                        </p>
                        <TextField
                            inputRef={register({ valueAsNumber: true })}
                            type="number"
                            variant="outlined"
                            name="variations_sales_vip_price"
                            label="VIP Price"
                            className="half"
                        />
                        <TextField
                            inputRef={register({ valueAsNumber: true })}
                            type="number"
                            variant="outlined"
                            name="variations_sales_member_price"
                            label="Member Price"
                            className="half"
                        />
                        <TextField
                            inputRef={register({ valueAsNumber: true })}
                            type="number"
                            variant="outlined"
                            name="variations_sales_market_price"
                            label="Market Price"
                            className="half"
                        />
                        <TextField
                            inputRef={register({ valueAsNumber: true })}
                            type="number"
                            variant="outlined"
                            name="variations_sales_inventory"
                            label="Inventory"
                            className="half"
                        />
                        <CustomFormButton
                            className="half"
                            ccolor="blue"
                            cvariant="contained"
                            variant="outlined"
                            type="submit"
                            fullWidth
                        >
                            Save changes
                        </CustomFormButton>
                        <CustomFormButton
                            className="half"
                            ccolor="blue"
                            cvariant="text"
                            fullWidth
                            onClick={() => dispatch(unsetPopup())}
                        >
                            Later
                        </CustomFormButton>
                    </FormCategory>
                </form>
            </CustomDenyDialogContent>
        </Popup>
    );
};

export default VariationsBulkActionForm;
