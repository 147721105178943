import { useState, useEffect } from "react";
import { styled } from "@material-ui/core/styles";
import { LoadingContainer } from "components/MerchantCentre/AdministrationConsole/Application/Details/StyledComponent";
import { CircularProgress } from "@material-ui/core";
import { Form } from "components/General/Form";
import { FieldType } from "components/General/Field";
import { FormButton } from "components/Global";
import { cloneDeep } from "lodash";
import { Prompt } from "react-router-dom";
import { useApiWithErrorHandle, useShowSnackbar } from "service/apis/merchant/utilHook";
import * as settingAPI from "service/apis/merchant/setting";
import { extractYouTubeID } from "helper/validate";

const PageContainer = styled("div")({
    margin: "0px 35px",
    position: "relative",
});

const Grid = styled("div")({
    display: "flex",
});

const FormSection = styled("div")({
    flexGrow: 1,
});

const PanelSection = styled("div")({
    width: 380,
    paddingLeft: "25px",
    paddingTop: "13px",
});

const InfoText = styled("p")({
    color: "#888888",
});

const RightPanel = styled("div")(({ theme }) => ({
    border: `1px solid ${theme.yoho.md_color.grey[400]}`,
    minWidth: "380px",
    padding: "30px",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    gap: "20px",
    height: "fit-content",
    borderRadius: 16,
    position: "sticky",
    top: "35px",
    "& > *": {
        width: "100% !important",
    },
}));

const initFormSections = [
    {
        fields: [
            {
                label: "Store Name English",
                name: "store_name_english",
                type: FieldType.TEXT,
                readOnly: true,
            },
            {
                label: "Store Name Chinese",
                name: "store_name_chinese",
                type: FieldType.TEXT,
                readOnly: true,
            },
            {
                label: "Store Description Chinese",
                name: "store_description_chinese",
                type: FieldType.TEXT,
                fullWidth: true,
                multiline: true,
                maxRows: 3,
            },
            {
                label: "Store Description English",
                name: "store_description_english",
                type: FieldType.TEXT,
                fullWidth: true,
                multiline: true,
                maxRows: 3,
            },
            {
                label: "Enable category layout on store page",
                name: "enable_store_cat",
                type: FieldType.SWITCH,
                fullWidth: true,
            },
        ],
    },
    {
        label: "Store Logo",
        fields: [
            {
                name: "image_url",
                type: FieldType.IMAGE_UPLOAD,
                width: 200,
                height: 200,
                hints: "The suggested size of this image is 200 x 200px.",
            },
        ],
    },
    {
        label: "Store Primary Banner",
        fields: [
            {
                name: "primary_banner_url",
                type: FieldType.IMAGE_UPLOAD,
                width: 1330,
                height: 235,
                hints: "The suggested size of this image is 1330 x 235px.",
            },
        ],
    },
    {
        label: "Store Secondary Banner",
        fields: [
            {
                name: "secondary_banner_url",
                type: FieldType.IMAGE_UPLOAD,
                width: 1200,
                height: 628,
                hints: "The suggested size of this image is 1200 x 628px.",
            },
        ],
    },
    {
        fields: [
            {
                label: "Store Secondary Banner Title English",
                name: "secondary_banner_title_english",
                type: FieldType.TEXT,
            },
            {
                label: "Store Secondary Banner Title Chinese",
                name: "secondary_banner_title_chinese",
                type: FieldType.TEXT,
            },
        ],
    },
    {
        fields: [
            {
                label: "Store Secondary Banner Description English",
                name: "secondary_banner_description_english",
                type: FieldType.TEXT,
                fullWidth: true,
            },
            {
                label: "Store Secondary Banner Description Chinese",
                name: "secondary_banner_description_chinese",
                type: FieldType.TEXT,
                fullWidth: true,
            },
        ],
    },
    {
        label: "Store Youtube Video",
        fields: [
            {
                label: "Store Youtube Video link",
                name: "slide_youtube_url",
                type: FieldType.TEXT,
                fullWidth: true,
            },
        ],
    },
    {
        fields: [
            {
                label: "Store Youtube Video Title English",
                name: "slide_youtube_title_english",
                type: FieldType.TEXT,
            },
            {
                label: "Store Youtube Video Title Chinese",
                name: "slide_youtube_title_chinese",
                type: FieldType.TEXT,
            },
        ],
    },
    {
        fields: [
            {
                label: "Store Youtube Video Description English",
                name: "slide_youtube_description_english",
                type: FieldType.TEXT,
                fullWidth: true,
            },
            {
                label: "Store Youtube Video Description Chinese",
                name: "slide_youtube_description_chinese",
                type: FieldType.TEXT,
                fullWidth: true,
            },
        ],
    },
];

const validateFormData = ({
    image_url,
    store_description_chinese,
    store_description_english,
    slide_youtube_url,
    slide_youtube_title_chinese,
    slide_youtube_title_english,
    slide_youtube_description_chinese,
    slide_youtube_description_english,
    secondary_banner_url,
    secondary_banner_title_chinese,
    secondary_banner_title_english,
    secondary_banner_description_chinese,
    secondary_banner_description_english,
}) => {
    if (!image_url) {
        return ["Please upload the logo image", { image_url: "Please upload the logo image" }];
    }
    if (!store_description_chinese) {
        return ["This field cannot be empty", { store_description_chinese: "This field cannot be empty" }];
    }
    if (!store_description_english) {
        return ["This field cannot be empty", { store_description_english: "This field cannot be empty" }];
    }
    if (secondary_banner_url && !secondary_banner_title_english) {
        return ["This field cannot be empty", { secondary_banner_title_english: "This field cannot be empty" }];
    }
    if (secondary_banner_url && !secondary_banner_title_chinese) {
        return ["This field cannot be empty", { secondary_banner_title_chinese: "This field cannot be empty" }];
    }
    if (secondary_banner_url && !secondary_banner_description_english) {
        return ["This field cannot be empty", { secondary_banner_description_english: "This field cannot be empty" }];
    }
    if (secondary_banner_url && !secondary_banner_description_chinese) {
        return ["This field cannot be empty", { secondary_banner_description_chinese: "This field cannot be empty" }];
    }
    if (slide_youtube_url && extractYouTubeID(slide_youtube_url) === "") {
        return ["Invalid Youtube URL.", { slide_youtube_url: "Invalid Youtube URL." }];
    }
    if (slide_youtube_url && !slide_youtube_title_english) {
        return ["This field cannot be empty", { slide_youtube_title_english: "This field cannot be empty" }];
    }
    if (slide_youtube_url && !slide_youtube_title_chinese) {
        return ["This field cannot be empty", { slide_youtube_title_chinese: "This field cannot be empty" }];
    }
    if (slide_youtube_url && !slide_youtube_description_english) {
        return ["This field cannot be empty", { slide_youtube_description_english: "This field cannot be empty" }];
    }
    if (slide_youtube_url && !slide_youtube_description_chinese) {
        return ["This field cannot be empty", { slide_youtube_description_chinese: "This field cannot be empty" }];
    }
    return ["", {}];
};

export default function StorePage() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [modified, setModified] = useState(false);
    const [errors, setErrors] = useState({});
    const showSnackbar = useShowSnackbar();
    const getStorePageBasicConfig = useApiWithErrorHandle(settingAPI.getStorePageBasicConfig);
    const postStorePageBasicConfig = useApiWithErrorHandle(settingAPI.postStorePageBasicConfig);
    const getUploadPath = useApiWithErrorHandle(settingAPI.getUploadUrl);
    const postUploadImage = useApiWithErrorHandle(settingAPI.postUploadImage);

    const handleImageUpload = async (imageFile) => {
        const uploadPath = await getUploadPath();
        if (uploadPath) {
            const formData = new FormData();
            formData.append("image", imageFile);
            const result = await postUploadImage({ uploadPath, formData });
            return result?.publicUrl;
        }
    };

    const handleSubmit = async () => {
        const [errorMsg, fieldError] = validateFormData(data);
        if (errorMsg) {
            setErrors(fieldError);
            showSnackbar(errorMsg);
            return;
        }

        // if no banner or no url, then empty the input of the title and description
        if (!data.secondary_banner_url) {
            data.secondary_banner_title_english = "";
            data.secondary_banner_title_chinese = "";
            data.secondary_banner_description_english = "";
            data.secondary_banner_description_chinese = "";
            setData(data);
        }

        if (!data.slide_youtube_url) {
            data.slide_youtube_title_chinese = "";
            data.slide_youtube_title_english = "";
            data.slide_youtube_description_chinese = "";
            data.slide_youtube_description_english = "";
            setData(data);
        }

        // handle youtube url
        const youtubeID = extractYouTubeID(data.slide_youtube_url);
        if (youtubeID) {
            data.slide_youtube_url = `www.youtube.com/embed/${youtubeID}`;
        }

        setErrors({});
        setSubmitting(true);
        const result = await postStorePageBasicConfig(data);
        if (result) {
            showSnackbar("Changes saved successfully.", "success");
            setSubmitting(false);
            setModified(false);
            return;
        }
    };

    const changeHandler = (field, value) => {
        const newData = cloneDeep(data);
        newData[field] = value;
        setData(newData);
        setModified(true);
    };

    useEffect(() => {
        const onLoad = async () => {
            const result = await getStorePageBasicConfig();

            if (result) {
                // Logo
                initFormSections[1].fields[0].handleImageUpload = handleImageUpload;
                // Primary Banner
                initFormSections[2].fields[0].handleImageUpload = handleImageUpload;
                // Secondary Banner
                initFormSections[3].fields[0].handleImageUpload = handleImageUpload;

                setData(result);
                setLoading(false);
            }
        };

        if (data === null) {
            onLoad();
        }
    }, [data]);

    if (loading) {
        return (
            <LoadingContainer>
                <CircularProgress size={100} />
                Loading Store Page Config
            </LoadingContainer>
        );
    }

    return (
        <PageContainer>
            <h1>Store Page</h1>
            <InfoText>
                You can click{" "}
                <a href={`${process.env.REACT_APP_B2C_DOMAIN}/zh-hk/store/${data.id}`} target="_blank">
                    here
                </a>{" "}
                to view your store page. Note that changes made on this page may take a few minutes to be effective
                after the save button is clicked.
            </InfoText>
            <Grid>
                <FormSection>
                    <Form
                        sections={initFormSections}
                        values={data}
                        errors={errors}
                        defaultChangeHandler={changeHandler}
                    />
                </FormSection>
                <PanelSection>
                    <RightPanel>
                        <FormButton cvariant="contained" ccolor="blue" disabled={submitting} onClick={handleSubmit}>
                            {submitting ? "Loading . . . " : "Submit And Save"}
                        </FormButton>
                        <FormButton
                            cvariant="text"
                            ccolor="blue"
                            onClick={() => {
                                if (modified) {
                                    const proceed = window.confirm("Are you sure to discard all changes?");
                                    if (proceed) {
                                        setModified(false);
                                        setLoading(true);
                                        setErrors({});
                                        setData(null);
                                    }
                                }
                            }}
                        >
                            Discard Changes
                        </FormButton>
                    </RightPanel>
                </PanelSection>
            </Grid>
            <Prompt
                when={modified && !submitting}
                message="You have unsaved changes, are you sure you want to leave?"
            />
        </PageContainer>
    );
}
