import { Box, styled } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { ArrowUpward, ArrowDownward } from "@material-ui/icons";

const RefDiv = React.forwardRef(({ className, onClick, children }, ref) => {
    return (
        <div className={className} ref={ref} onClick={onClick}>
            {children}
        </div>
    );
});

const CellHead = styled(RefDiv)(({ pointer, ellipsis }) => ({
    position: "sticky",
    top: 0,
    background: "white",
    height: 40,
    display: "flex",
    gap: 5,
    alignItems: "center",
    borderTop: "1px solid #e0e0e0",
    borderBottom: "1px solid #e0e0e0",
    zIndex: 2,
    padding: "0 10px",
    cursor: pointer ? "pointer" : "unset",
    ...(ellipsis && { width: 600 }),
}));

const CellItem = styled(RefDiv)(({ theme, width, textstyle, disablewhitespace, ellipsis, height }) => ({
    ...(!width && { whiteSpace: disablewhitespace ? "break-spaces" : "pre" }),
    ...(textstyle && theme.yoho.typography[textstyle]),
    ...(ellipsis && { width: 600 }),
    padding: "0px 10px",
    height: height || 80,
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
    borderTop: "1px solid #e0e0e0",
    borderBottom: "1px solid #e0e0e0",
    fontSize: 14,
    fontWeight: 400,
}));

const Span = styled("span")(({ ellipsis }) => ({
    ...(ellipsis && {
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "inline-block !important",
        "-webkit-line-clamp": 3,
        "-webkit-box-orient": "vertical",
        whiteSpace: "nowrap",
        width: "100%",
    }),
}));

const Col = styled(RefDiv)(({ offset, width, setborder, ellipsis }) => ({
    flex: "auto",
    ...(setborder === "end" && {
        borderRight: "1px solid #e0e0e0",
    }),
    ...(width && { width, minWidth: width, maxWidth: width }),
    "&.fixed": {
        position: "sticky",
        left: offset || 0,
        zIndex: 5,
        background: "white",
    },
    ...(ellipsis && { width: 600, flex: "inherit" }),
}));

const SpecialColumnHead = ({ field, sortable, sort, sort_key, onSort, ellipsis }) => {
    const title = typeof field === "object" ? field.join(" ") : field;

    if (sortable) {
        return (
            <CellHead pointer={sort_key} onClick={onSort} ellipsis={ellipsis}>
                {title}
                {sort === "high_to_low" && <ArrowDownward style={{ color: "#9E9E9E" }} />}
                {sort === "low_to_high" && <ArrowUpward style={{ color: "#9E9E9E" }} />}
            </CellHead>
        );
    }
    return (
        <CellHead pointer={sort_key} onClick={onSort} ellipsis={ellipsis}>
            {title}
        </CellHead>
    );
};

const SpecialColumnBody = React.forwardRef(
    ({ type, render, data, onClick, width, textStyle, disableWhiteSpace, loading, ellipsis, height }, ref) => {
        if (loading) {
            return (
                <CellItem>
                    {!type && (
                        <Box
                            style={{
                                width: "100%",
                                height: "100%",
                            }}
                            display="flex"
                            alignItems="center"
                        >
                            <Skeleton
                                style={{
                                    width: "100%",
                                    height: "100%",
                                }}
                            />
                        </Box>
                    )}
                </CellItem>
            );
        }

        return type
            ? data.all.map((val, index) => (
                  <CellItem
                      textstyle={textStyle}
                      ref={ref}
                      key={`all_${index}`}
                      disablewhitespace={disableWhiteSpace?.toString()}
                      width={width}
                      ellipsis={ellipsis}
                      height={height}
                  >
                      {render ? (
                          render(val, data.cell[index])
                      ) : (
                          <div>
                              <i>undefined</i>
                          </div>
                      )}
                  </CellItem>
              ))
            : data.cell.map((val, index) => (
                  <CellItem
                      textstyle={textStyle}
                      ref={ref}
                      key={`cell_${index}`}
                      onClick={onClick}
                      disablewhitespace={disableWhiteSpace?.toString()}
                      width={width}
                      ellipsis={ellipsis}
                      height={height}
                  >
                      {render ? (
                          render(val)
                      ) : typeof val === "object" ? (
                          Object.values(val).join(" ")
                      ) : val === 0 || val === null || val === "" ? (
                          0
                      ) : (
                          <Span ellipsis={ellipsis} title={val}>
                              {val}
                          </Span>
                      )}
                  </CellItem>
              ));
    },
);

const Column = ({
    render,
    field,
    data,
    type,
    fixed,
    width,
    onClick,
    disableWhiteSpace,
    textStyle,
    sortable,
    sort,
    sort_key,
    onSort,
    ellipsis,
    height,
    ...props
}) => {
    const colRef = useRef();
    const cellRef = useRef();
    let offset = 0;
    const [endFixed, setEndFixed] = useState(false);
    const [startNotFixed, setStartNotFixed] = useState(false);

    useEffect(() => {
        offset = colRef?.current?.getBoundingClientRect().left - 116;
        if ([...colRef?.current?.classList]?.includes("fixed")) {
            if (colRef.current.nextSibling !== null) {
                if (![...colRef?.current?.nextSibling?.classList]?.includes("fixed")) {
                    setEndFixed(true);
                }
            }
        } else {
            if (colRef.current.previousSibling !== null) {
                if ([...colRef?.current?.previousSibling?.classList]?.includes("fixed")) {
                    setStartNotFixed(true);
                }
            }
        }
    }, [colRef]);

    return (
        <Col
            ref={colRef}
            width={width}
            {...props}
            {...(fixed && { className: "fixed", offset: offset })}
            {...(endFixed && { setborder: "end" })}
            {...(startNotFixed && { setborder: "start" })}
            {...(ellipsis && { ellipsis })}
        >
            <SpecialColumnHead
                field={props.label || field}
                {...(props.sort && { sort: true })}
                {...(sort && { sort })}
                {...(sortable && { sortable })}
                {...(sort_key && { sort_key })}
                {...(onSort && { onSort })}
                {...(ellipsis && { ellipsis })}
            />
            <SpecialColumnBody
                key={JSON.stringify(data)}
                loading={props.loading}
                {...(type && { type })}
                {...(render && { render })}
                {...(data && { data })}
                {...(cellRef && { ref: cellRef })}
                {...(onClick && { onClick })}
                {...(width && { width })}
                {...(disableWhiteSpace && { disableWhiteSpace })}
                {...(textStyle && { textStyle })}
                {...(ellipsis && { ellipsis })}
                {...(height && { height })}
            />
        </Col>
    );
};

Column.defaultProps = {
    __type: "Column",
};
export default Column;
