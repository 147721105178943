import { InputAdornment, TextField } from "@material-ui/core";
import { FormCategory } from "components/Global";

export default ({ inventoryRef, marketRef, vipRef, memberRef, skuData, watch }) => {
    let promoPrice = null;
    if (skuData.discounts.length > 0) {
        const discount = skuData.discounts[0];
        const shopPrice = watch("variations_sales_member_price");
        switch (discount.discount_type) {
            case "percentage_discount": {
                promoPrice = (shopPrice * (100 - discount.discount_percentage)) / 100;
                promoPrice = Math.ceil(promoPrice);
                break;
            }
            case "price_discount": {
                promoPrice = parseInt(skuData.promote_price);
                break;
            }
            case "amount_discount": {
                promoPrice = shopPrice - discount.discount_amount;
                break;
            }
        }
    }

    return (
        <FormCategory style={{ padding: "10px 0px"}}>
            <TextField
                inputRef={vipRef}
                name="variations_sales_vip_price"
                variant="outlined"
                label="VIP Price"
                type="number"
                inputProps={{
                    step: 0.01,
                }}
                InputProps={{
                    startAdornment: <InputAdornment position="start">HK$</InputAdornment>,
                }}
            />
            <TextField
                inputRef={memberRef}
                name="variations_sales_member_price"
                variant="outlined"
                label="Member Price"
                type="number"
                inputProps={{
                    step: 0.01,
                }}
                InputProps={{
                    startAdornment: <InputAdornment position="start">HK$</InputAdornment>,
                }}
            />
            <TextField
                inputRef={marketRef}
                name="variations_sales_market_price"
                variant="outlined"
                label="Market Price"
                type="number"
                inputProps={{
                    step: 0.01,
                }}
                InputProps={{
                    startAdornment: <InputAdornment position="start">HK$</InputAdornment>,
                }}
            />
            <TextField
                inputRef={inventoryRef}
                name="variations_sales_inventory"
                variant="outlined"
                label="Inventory"
                type="number"
                inputProps={{
                    step: 1,
                }}
            />
            {promoPrice !== null && (
                <TextField
                    disabled
                    variant="outlined"
                    label="Promotion Price"
                    type="number"
                    value={promoPrice}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">HK$</InputAdornment>,
                    }}
                />
            )}
        </FormCategory>
    );
};
