import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "service/actions/site";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        "& > * + *": {
            marginTop: theme.spacing(2),
        },
    },
}));

export default () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const reduxState = useSelector((state) => state.site.snackbar);

    const handleClose = (event, reason) => {
        // if (reason === 'clickaway') {
        //     return;
        // }
        dispatch(setSnackbar({ ...reduxState, open: false }));
    };

    return (
        <div className={classes.root}>
            <Snackbar open={reduxState.open} autoHideDuration={2000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={reduxState.severity}>
                    {reduxState.message}
                </Alert>
            </Snackbar>
            {/* <Alert severity="error">This is an error message!</Alert>
            <Alert severity="warning">This is a warning message!</Alert>
            <Alert severity="info">This is an information message!</Alert>
            <Alert severity="success">This is a success message!</Alert> */}
        </div>
    );
};
