import { Box, styled, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { FormButton } from "components/Global";
import usePopup from "hooks/usePopup";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setAddProductVariation } from "service/actions/merchant";
import VariationsPopup from "../popup/VariationsPopup";
import AutoCompletePopper from "components/General/Popper";

const VariationRow = styled("div")({
    margin: "10px 0px 15px",
    display: "flex",
    "& > .selection": {
        width: "50%",
        display: "flex",
        gap: 10,
        alignItems: "center",
    },
});

const AddButton = styled(FormButton)({
    height: 35,
    width: "max-content",
});

export default ({ variationData, current }) => {
    const dispatch = useDispatch();
    const [variation, setVariation] = useState(null);
    const popup = usePopup();

    const variationOptions = [
        ...variationData.variation_attribute,
        {
            sub_category_id: variationData.sub_category,
            attribute_name_english: "+ Create New Attribute",
            attribute_name_chinese: "",
        },
    ];

    const handleVariationSelect = (event, selected) => {
        if (selected === null) return;

        // Create new attribute
        if (!selected.attribute_id) {
            setVariation(null);
            popup(VariationsPopup, {
                mode: "create",
                title: "Create New Attribute",
                sub_category: variationData.sub_category,
                action: (data) => {
                    data = { attribute_id: `temp_${Date.now()}`, ...data, items: [] };
                    dispatch(setAddProductVariation(data));
                },
            });
        } else {
            setVariation(selected);
        }
    };

    const insertVariation = (data) => {
        data = { ...data, items: [] };
        dispatch(setAddProductVariation(data));
        setVariation(null);
    };

    return (
        <VariationRow className="full">
            <div className="selection">
                <Autocomplete
                    fullWidth
                    PopperComponent={AutoCompletePopper}
                    options={variationOptions}
                    getOptionLabel={(option) => `${option.attribute_name_english} ${option.attribute_name_chinese}`}
                    renderOption={(option, props) => (
                        <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                            {option.attribute_name_english} {option.attribute_name_chinese}
                        </Box>
                    )}
                    value={variation}
                    onChange={handleVariationSelect}
                    getOptionDisabled={(option) => current.some((used) => used.attribute_id === option.attribute_id)}
                    renderInput={(params) => (
                        <TextField variant="outlined" {...params} label="Add Variation Attribute" />
                    )}
                />
                {variation !== "" && variation !== null && (
                    <AddButton onClick={() => insertVariation(variation)}>Add</AddButton>
                )}
            </div>
        </VariationRow>
    );
};
