import { DialogContent, DialogTitle, styled, TextField } from "@material-ui/core";
import { FormButton, FormCategory } from "components/Global";
import { FormText } from "components/Global/Form";
import Popup from "components/Global/Layout/popup";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import EditGeneral from "../tabs/EditGeneral";
import EditSales from "../tabs/EditSales";
import AddImagesDropzone from "../tabs/ImagesDropzone";
import { unsetPopup } from "service/actions/site";
import { cloneDeep } from "lodash";
import { updateResellProductDraft } from "service/actions/merchant";
import { Alert } from "@material-ui/lab";

const CustomDialogTitle = styled(DialogTitle)(({ theme }) => ({
    ...theme.yoho.typography.h4,
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    "& p": {
        margin: 0,
    },
}));

const CustomDialogContent = styled(DialogContent)(({ theme }) => ({
    borderTop: `1px solid ${theme.yoho.md_color.grey[300]}`,
}));

const CustomFormButton = styled(FormButton)({
    alignSelf: "center",
    marginBottom: "10px",
});

const MenuContainer = styled("div")({
    display: "flex",
});

const SkeletonImage = styled("div")({
    width: 115,
    height: 115,
    borderRadius: 12,
    border: "1px dashed black",
});

const ImagePreview = styled("img")({
    width: 115,
    height: 115,
    borderRadius: 12,
    objectFit: "contain",
});

const Menu = styled("h4")(({ active, theme, type }) => ({
    ...theme.yoho.typography[type],
    color: theme.yoho.md_color.grey[800],
    display: "flex",
    position: "relative",
    "&.active": {
        color: theme.yoho.md_color.blue[500],
        "&:after": {
            content: "''",
            background: theme.yoho.md_color.blue[500],
            width: "100%",
            position: "absolute",
            height: "1px",
            left: 0,
            bottom: "-14px",
        },
    },
    textTransform: "capitalize",
    cursor: "pointer",
    padding: "0 15px",
    margin: "13px 0",
    textAlign: "center",
    "& .bubble": {
        color: theme.yoho.md_color.red[500],
    },
}));

const Title = styled("h4")(({ theme }) => ({
    ...theme.yoho.typography["button"],
    color: theme.yoho.md_color.grey[800],
    padding: 0,
    margin: "13px 0",
}));

const Divider = styled("div")(({ theme }) => ({
    width: "100%",
    borderBottom: `1px solid ${theme.yoho.md_color.grey[300]}`,
    marginBottom: "20px",
}));

const safeGetImageURL = (imageSlot) => {
    try {
        const imageURL = URL.createObjectURL(imageSlot.file);
        return imageURL;
    } catch (error) {
        // Redux persist cannot save non-serializable file object
        if (imageSlot.publicURL) return imageSlot.publicURL;
        return null;
    }
};

const validateFields = (formData) => {
    try {
        if (!formData.variations_sales_market_price || !formData.variations_sales_member_price) {
            throw { message: "Member price and market price must be filled in." };
        }

        if (
            formData.variations_sales_market_price <= 0 ||
            formData.variations_sales_member_price <= 0 ||
            formData.variations_sales_vip_price <= 0
        ) {
            throw { message: "Prices must be more than zero." };
        }

        if (formData.variations_sales_member_price > formData.variations_sales_market_price) {
            throw { message: "Member price cannot be more than market price." };
        }

        if (formData.variations_sales_vip_price >= formData.variations_sales_member_price) {
            throw { message: "VIP price must be less than member price or left empty." };
        }

        if (formData.variations_general_weight <= 0) {
            throw { message: "Weight must be larger than zero for shipping fee calculation." };
        }

        if (!formData.image_slots.reduce((prev, { occupied }) => prev || occupied, false)) {
            throw { message: "Product must have at least one image." };
        }

        if (
            formData.variations_sales_member_price < formData.variations_sales_market_price * 0.2 ||
            formData.variations_sales_vip_price < formData.variations_sales_market_price * 0.2
        ) {
            throw {
                message: "Member price or VIP price is less than 20% of market price. Confirm to proceed?",
                error: false,
                warning: true,
            };
        }

        return { error: false };
    } catch (error) {
        return {
            error: true,
            ...error,
        };
    }
};

export default ({ title, id }) => {
    const dispatch = useDispatch();

    const productData = useSelector((state) => state.merchant.product.selected.data);
    const currentSku = productData.sku.find(({ goods_id }) => goods_id === id);

    const { register, handleSubmit } = useForm({
        mode: "onSubmit",
        reValidateMode: "onSubmit",
        defaultValues: currentSku,
    });

    const [errorMsg, setErrorMsg] = useState("");
    const [imageSlots, setImageSlots] = useState(cloneDeep(currentSku.image_slots));
    const [thumbSlots, setThumbSlots] = useState(cloneDeep(currentSku.thumb_slots));

    const imageUploading =
        imageSlots.reduce((prev, curr) => prev || !!curr.isUploading, false) ||
        thumbSlots.reduce((prev, curr) => prev || !!curr.isUploading, false);

    // Handle save
    const onSubmit = (formData) => {
        delete formData.variations_general_gtin;

        const savePayload = {
            ...currentSku,
            ...formData,
            image_slots: imageSlots,
            thumb_slots: thumbSlots,
        };

        const { error, message, warning } = validateFields(savePayload);

        if (error) {
            setErrorMsg(message);
            return;
        }

        if (warning) {
            const proceed = window.confirm(message);
            if (!proceed) return;
        }

        dispatch(updateResellProductDraft(savePayload));
        dispatch(unsetPopup());
    };

    const mainImageURL = imageSlots.reduce((prev, curr) => {
        if (curr.isMain && curr.occupied) {
            return safeGetImageURL(curr);
        } else {
            return prev;
        }
    }, null);

    return (
        <Popup size="md" fullWidth>
            <CustomDialogTitle>{title}</CustomDialogTitle>
            <CustomDialogContent>
                {errorMsg && (
                    <Alert severity="error" style={{ margin: "15px 20px 0px" }}>
                        {errorMsg}
                    </Alert>
                )}
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormCategory justifycontent="center">
                        <div className="full flex align-items-center gap-10">
                            <FormText>
                                <p className="body1">{currentSku.variants_mixed}</p>
                            </FormText>
                        </div>
                        <div className="full flex gap-10">
                            <div style={{ cursor: "pointer" }}>
                                {mainImageURL ? <ImagePreview src={mainImageURL} /> : <SkeletonImage />}
                            </div>
                            <div className="w-12 flex gap-10 col">
                                <TextField
                                    variant="outlined"
                                    label="Product Name (Traditional Chinese)"
                                    className="w-12"
                                    value={currentSku.vital_info_product_name_chinese}
                                    disabled
                                />
                                <TextField
                                    variant="outlined"
                                    label="Product Name (English)"
                                    className="w-12"
                                    value={currentSku.vital_info_product_name_english}
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="full">
                            <Title>{"Images"}</Title>
                            <Divider />
                            <div style={{ display: "block", textAlign: "center" }} className="full">
                                <AddImagesDropzone
                                    imageSlots={imageSlots}
                                    setImageSlots={setImageSlots}
                                    thumbSlots={thumbSlots}
                                    setThumbSlots={setThumbSlots}
                                />
                            </div>

                            <Title>{"General"}</Title>
                            <Divider />
                            <div style={{ display: "inline-flex" }}>
                                <EditGeneral
                                    isExistingSku={
                                        typeof currentSku.goods_id === "number" ||
                                        !currentSku.goods_id.startsWith("temp")
                                    }
                                    inputRef={register()}
                                    customRef={register({ valueAsNumber: true })}
                                />
                            </div>

                            <Title>{"Sales"}</Title>
                            <Divider />
                            <div style={{ display: "inline-flex" }}>
                                <EditSales
                                    defaultData={currentSku}
                                    inputRef={register()}
                                    vipRef={register({ valueAsNumber: true })}
                                    memberRef={register({ valueAsNumber: true })}
                                    marketRef={register({ valueAsNumber: true })}
                                />
                            </div>
                        </div>

                        <CustomFormButton
                            disabled={imageUploading}
                            className="half"
                            ccolor="blue"
                            cvariant="contained"
                            type="submit"
                            fullWidth
                        >
                            {imageUploading ? "Loading..." : "Save"}
                        </CustomFormButton>
                    </FormCategory>
                </form>
            </CustomDialogContent>
        </Popup>
    );
};
