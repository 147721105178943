export const DEFAULT_COOKIE_PARAMS = {
    expires: 360,
    path: "/",
    domain: window.location.hostname,
};

export const INFINITE_COOKIE_PARAMS = {
    expires: 160000,
    path: "/",
    domain: window.location.hostname,
};

export const stringSize = (str) => {
    return str?.replace(/[^\x00-\xff]/g, "OO").length;
};

export const trimString = (str, n) => {
    let len = 0;
    let tmpStr = "";
    for (let i = 0; i < str?.length; i++) {
        if (/[\u4e00-\u9fa5]/?.test(str[i])) len += 2;
        else len += 1;

        if (len > n) break;
        else tmpStr += str[i];
    }
    return tmpStr;
};

export const toFormData = (data = {}) => {
    let formData = new FormData();

    Object.keys(data).forEach((key) => {
        if (data[key] === null) return;
        if (data[key] instanceof File) {
            formData.append(key, data[key]);
        } else if (typeof data[key] === "object") {
            if (Array.isArray(data[key])) {
                if (data[key][0] instanceof File) {
                    data[key].forEach((item) => {
                        formData.append(key, item);
                    });
                } else {
                    formData.append(key, JSON.stringify(data[key]));
                }
            } else {
                formData.append(key, JSON.stringify(data[key]));
            }
        } else {
            formData.append(key, data[key]);
        }
    });

    return formData;
};

export const onImageError = (event) => {
    event.target.src = "/assets/images/missing-image.svg";
    event.target.style.backgroundColor = "#00ACEE";
    event.target.style.transform = "scale(1.7)";
};
