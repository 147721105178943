import { CircularProgress, styled } from "@material-ui/core";
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";
import api from "service/apis/config";
import * as siteAction from "service/actions/site";

const DropContainer = styled("div")((props) => ({
    width: 155,
    height: 155,
    borderRadius: 12,
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    alignItems: "center",
    justifyContent: "center",
    background: "#f2f2f2",
    padding: "20px",
    cursor: "pointer",
    "& p": {
        fontWeight: "bold",
        fontSize: 12,
        lineHeight: "16px",
        color: "#00ACEE",
        textAlign: "center",
    },
}));

const DeleteButton = styled("div")({
    padding: "5px 8px",
    position: "absolute",
    right: 0,
    top: 0,
    borderTopRightRadius: 12,
    borderBottomLeftRadius: 12,
    fontSize: 14,
    color: "#ffffff",
    backgroundColor: "#C84A4A",
    cursor: "pointer",
    "&:hover": {
        backgroundColor: "#CA1010",
    },
});

const SetMainButton = styled("div")({
    lineHeight: "30px",
    position: "absolute",
    bottom: 0,
    textAlign: "center",
    borderBottomLeftRadius: 12,
    borderBottomRightRadius: 12,
    width: 155,
    fontSize: 14,
    color: "#ffffff",
    backgroundColor: "#50B3E5",
    cursor: "pointer",
    "&:hover": {
        backgroundColor: "#00ACEE",
    },
});

const MainImageBanner = styled("p")({
    lineHeight: "30px",
    position: "absolute",
    margin: 0,
    bottom: 0,
    textAlign: "center",
    borderBottomLeftRadius: 12,
    borderBottomRightRadius: 12,
    width: 155,
    fontSize: 14,
    color: "#ffffff",
    backgroundColor: "#00ACEE",
});

const Gallery = styled("div")(() => ({
    display: "flex",
    gap: 18,
    flexDirection: "row",
    justifyContent: "left",
    margin: "0px 0px 20px",
}));

const PreviewImageHolder = styled("div")(({ url, isUploading }) => ({
    display: "inline-block",
    width: 155,
    height: 155,
    borderRadius: 12,
    backgroundImage: `url(${url})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    position: "relative",
    "& > div": {
        display: isUploading ? "block" : "none",
    },
    "&:hover > div": {
        display: "block",
    },
}));

const UploadingBackground = styled("div")({
    position: "absolute",
    width: 155,
    height: 155,
    borderRadius: 12,
    background: "rgba(0, 0, 0, 0.2)",
});

const UploadingHint = styled(CircularProgress)({
    margin: 60,
});

const safeGetImageURL = (imageSlot) => {
    try {
        const imageURL = URL.createObjectURL(imageSlot.file);
        return imageURL;
    } catch (error) {
        // Redux persist cannot save non-serializable file object
        if (imageSlot.publicURL) return imageSlot.publicURL;
        return null;
    }
};

const Previewer = ({ setMain, onDelete, position, isUploading, ...image }) => {
    const imageURL = safeGetImageURL(image);

    return (
        <PreviewImageHolder url={imageURL} isUploading={isUploading}>
            {isUploading ? (
                <UploadingBackground>
                    <UploadingHint />
                </UploadingBackground>
            ) : (
                <>
                    <DeleteButton onClick={() => onDelete(position)}>Delete</DeleteButton>
                    {position !== 0 ? (
                        <SetMainButton onClick={() => setMain(position)}>Set as Main Image</SetMainButton>
                    ) : (
                        <MainImageBanner>Main Image</MainImageBanner>
                    )}
                </>
            )}
        </PreviewImageHolder>
    );
};

export default ({ imageSlots, setImageSlots, thumbSlots, setThumbSlots }) => {
    const numImages = imageSlots.reduce((prev, curr) => (curr.occupied ? prev + 1 : prev), 0);
    const dispatch = useDispatch();

    const onDrop = async (acceptedFiles, fileRejections) => {
        if (fileRejections[0]) {
            const { errors } = fileRejections[0];
            const message =
                errors[0].code === "file-too-large" ? "Image must not be larger than 5MB" : errors[0].message;
            dispatch(
                siteAction.setSnackbar({
                    open: true,
                    message: message,
                    severity: "error",
                }),
            );
            return;
        }

        const emptyIndex = imageSlots.findIndex(({ occupied, uploadURL }) => !occupied && uploadURL);
        const imageUploadURL = imageSlots[emptyIndex].uploadURL;
        const thumbUploadURL = thumbSlots[emptyIndex].uploadURL;

        setImageSlots((prev) => {
            prev[emptyIndex].file = acceptedFiles[0];
            prev[emptyIndex].occupied = true;
            prev[emptyIndex].isUploading = true;
            if (numImages === 0) prev[emptyIndex].isMain = true;
            return [...prev];
        });

        setThumbSlots((prev) => {
            prev[emptyIndex].occupied = true;
            prev[emptyIndex].isUploading = true;
            return [...prev];
        });

        try {
            const thumbForm = new FormData();
            thumbForm.append("image", acceptedFiles[0]);
            const { data: thumbResponse } = await api.post(thumbUploadURL, thumbForm);
            if (thumbResponse.error)
                throw new Error(thumbResponse.message || "Upload error. Consider login again and retry.");

            const thumbResult = thumbResponse.result;
            setThumbSlots((prev) => {
                prev[emptyIndex].publicURL = thumbResult.publicUrl;
                prev[emptyIndex].isUploading = false;
                return [...prev];
            });

            const imageForm = new FormData();
            imageForm.append("image", acceptedFiles[0]);
            const { data: imageResponse } = await api.post(imageUploadURL, imageForm);
            if (imageResponse.error)
                throw new Error(imageResponse.message || "Upload error. Consider login again and retry.");

            const imageResult = imageResponse.result;
            setImageSlots((prev) => {
                prev[emptyIndex].publicURL = imageResult.publicUrl;
                prev[emptyIndex].isUploading = false;
                return [...prev];
            });
        } catch (error) {
            setImageSlots((prev) => {
                prev[emptyIndex].file = null;
                prev[emptyIndex].occupied = false;
                prev[emptyIndex].isUploading = false;
                prev[emptyIndex].isMain = false;
                return [...prev];
            });
            setThumbSlots((prev) => {
                prev[emptyIndex].occupied = false;
                prev[emptyIndex].isUploading = false;
                return [...prev];
            });
            dispatch(
                siteAction.setSnackbar({
                    open: true,
                    message: error.message,
                    severity: "error",
                }),
            );
        }
    };

    const onDelete = (idx) => {
        setImageSlots((prev) => {
            const slotToClear = prev[idx];
            if (slotToClear.isMain && prev[1].occupied) {
                prev[1].isMain = true;
            }
            slotToClear.isMain = false;
            slotToClear.occupied = false;
            slotToClear.file = null;
            prev.splice(idx, 1);
            return [...prev, slotToClear];
        });

        setThumbSlots((prev) => {
            const slotToClear = prev[idx];
            slotToClear.occupied = false;
            prev.splice(idx, 1);
            return [...prev, slotToClear];
        });
    };

    const setMain = (idx) => {
        setImageSlots((prev) => {
            prev = prev.map((slot) => ({ ...slot, isMain: false }));
            const mainSlot = prev[idx];
            mainSlot.isMain = true;
            prev.splice(idx, 1);
            return [mainSlot, ...prev];
        });

        setThumbSlots((prev) => {
            prev = prev.map((slot) => ({ ...slot, isMain: false }));
            const mainSlot = prev[idx];
            mainSlot.isMain = true;
            prev.splice(idx, 1);
            return [mainSlot, ...prev];
        });
    };

    const { getRootProps, getInputProps } = useDropzone({
        accept: "image/jpeg,image/png,image/jpg",
        maxSize: 5 * 1024 * 1024,
        multiple: false,
        onDrop,
    });

    return (
        <Gallery>
            {imageSlots
                .filter((slot) => slot.occupied)
                .map((img, idx) => (
                    <Previewer position={idx} setMain={setMain} onDelete={onDelete} {...img} />
                ))}
            {numImages < 5 && (
                <DropContainer {...getRootProps()}>
                    <input {...getInputProps()} />
                    <img src="/assets/icon/imgicon.svg" />
                    <p>Drag an image here or click to upload</p>
                </DropContainer>
            )}
        </Gallery>
    );
};
