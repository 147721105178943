import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Checkbox,
    debounce,
    Divider,
    Icon,
    InputAdornment,
    styled,
    TextField,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { FormButton, YHPagination, YohoButtonIcon, YohoProductFileUpload } from "components/Global";
import { FormText } from "components/Global/Form";
import { TextSelection, UncontrolledNormalSelect } from "components/Global/Form/NormalSelect";
import Table from "components/Global/Table";
import Column from "components/Global/Table/Column";
import Pagination from "components/Global/Table/Pagination";
import { Topbar, Topleft, TopRight } from "components/Global/Table/Topbar";
import Container from "components/MerchantCentre/Container";
import Price from "components/MerchantCentre/MerchantConsole/Products/List/components/Price";
import Product from "components/MerchantCentre/MerchantConsole/Products/List/components/Product";
import Status from "components/MerchantCentre/MerchantConsole/Products/List/components/Status";
import { setPLStatus, setProductChecklist, setProductParam } from "service/actions/merchant";
import { setRedirect } from "service/actions/site";
import Cost from "./components/Cost";
import SPUStatus from "./components/SPUStatus";
import { setSnackbar } from "service/actions/site";
import ProductActions from "./components/Actions";
import useExportToExcel from "helper/exportTable";
import ImageUploadDialog from "./components/ImageUploadDialog";
import usePopup from "hooks/usePopup";

const ProductContainer = styled("div")({ display: "flex", flexDirection: "column", width: "100%", gap: 30 });
const AddButton = styled(FormButton)({ width: "fit-content", height: 36 });
const BulkUpdateButton = styled(FormButton)({
    width: 252,
    height: 35,
    padding: "9px 16px",
    whiteSpace: "nowrap",
    color: "#424242",
    border: "1px solid #424242",
    borderRadius: 18,
    backgroundColor: "white",
});

const Row = styled("div")((props) => ({
    display: "flex",
    justifyContent: "space-between",
    "& .title": {
        display: "flex",
        gap: 50,
        alignItems: "center",
    },
    "& .filter": {
        display: "flex",
        gap: 20,
    },
    "& .show": {},
}));

const skuOption = [
    { name: "Show by SKU", value: "sku" },
    { name: "Show by SPU", value: "spu" },
];

const showOptions = [
    { id: 1, name: "30", value: 30 },
    { id: 2, name: "50", value: 50 },
    { id: 3, name: "100", value: 100 },
    { id: 4, name: "200", value: 200 },
];

const CustomButton = styled(FormButton)({
    height: 36,
    width: "fit-content",
});

const StyledDialogTitle = styled(DialogTitle)({
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "26px",
    color: "#424242",
    textAlign: "left",
});

const StyledDialogContentText = styled(DialogContentText)({
    fontSize: "16px",
    lineHeight: "21px",
    color: "#424242",
    textAlign: "left",
    display: "block",
});

const StyledNoteTitle = styled("p")({
    fontSize: "14px",
    lineHeight: "18px",
    color: "#9E9E9E",
    textAlign: "left",
});

const StyledNoteContent = styled("p")({
    fontSize: "14px",
    lineHeight: "18px",
    color: "#9E9E9E",
    textAlign: "left",
});

const StyledDialogActions = styled(DialogActions)({
    justifyContent: "center",
    paddingBottom: "20px",
});

const ErrorBox = styled("div")({
    padding: 16,
    background: "#FEEAED",
    borderRadius: 8,
    color: "#E8422E",
});

const ErrorTitle = styled("div")({
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "21px",
});

const ErrorContent = styled("div")({
    fontSize: "14px",
    lineHeight: "18px",
});

export default () => {
    const dispatch = useDispatch();
    const productData = useSelector((state) => state.merchant.product.list);
    const productLoading = useSelector((state) => state.merchant.loading);
    const params = useSelector((state) => state.merchant.product.params);
    const checkedProducts = useSelector((state) => state.merchant.product.checkbox_ids.list_goods_ids);

    const [exportParam, setExportParam] = useState(null);
    const { isExporting } = useExportToExcel(exportParam);
    const listedOptions = [
        { name: `All (${productData?.unlisted + productData?.listed})`, value: "all" },
        { name: `Listed (${productData?.listed})`, value: "listed" },
        { name: `Unlisted (${productData?.unlisted})`, value: "unlisted" },
    ];

    const listedSPU = [{ name: `Total Product (${productData?.total_products})`, value: "all" }];
    const isShowYohoQty = productData?.showYohoQty ?? false;

    const CheckColumn = (e) => {
        return (
            <Checkbox
                color="primary"
                size="small"
                checked={checkedProducts.includes(e.goods_id)}
                onChange={(_, isChecked) => dispatch(setProductChecklist(e.goods_id, isChecked, "list"))}
            />
        );
    };

    const setParamDebounce = debounce((newParam) => dispatch(setProductParam(newParam)), 300);

    const handleSearch = (e) => {
        setParamDebounce({ search: e.target.value });
        setValue(e.target.value);
    };

    const [value, setValue] = useState("");
    const handleClear = () => {
        setParamDebounce({ search: "" });
        setValue("");
    };

    const [uploadState, setUploadState] = useState("");
    const [isUploadLoading, setIsUploadLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [showUploadDialog, setShowUploadDialog] = useState(false);

    const closeUploadDialog = () => {
        setShowUploadDialog(false);
        setUploadState("");
    };

    useEffect(() => {
        if (uploadState === "success") {
            dispatch(
                setSnackbar({
                    open: true,
                    message: "Bulk update of inventory and prices has been made successfully",
                    severity: "success",
                }),
            );
            closeUploadDialog();
            dispatch(
                setProductParam({
                    status: params.status,
                    page: params.page,
                }),
            );
        }
    }, [uploadState]);

    useEffect(() => {
        if (productData?.products) {
            dispatch(
                setProductChecklist(
                    productData.products.map((item) => item.goods_id),
                    false,
                    "list",
                ),
            );
        }
        if (params.show === "spu") {
            dispatch(setProductParam({ status: "all", page: 1 }));
        }
    }, [params]);

    // Reset filter to default when component unmount
    useEffect(() => {
        return () => {
            dispatch(
                setProductParam({
                    field: "goods_id",
                    lang: "zh_tw",
                    page: 1,
                    search: "",
                    show: "sku",
                    size: 30,
                    sort: "high_to_low",
                    status: "all",
                }),
            );
        };
    }, []);

    const popup = usePopup();
    const onListProductImageClick = (goodsId, goodsName) => {
        if (params.show === "sku") popup(ImageUploadDialog, { goodsId, goodsName });
    };

    return (
        !productLoading && (
            <Container>
                <ProductContainer>
                    <Row>
                        <div className="title">
                            <FormText>
                                <h2>Products</h2>
                            </FormText>
                            <AddButton
                                cvariant="contained"
                                ccolor="blue"
                                onClick={() => dispatch(setRedirect("/products/add"))}
                            >
                                Add New Product
                            </AddButton>
                        </div>
                    </Row>
                    <Row>
                        <div className="filter">
                            <TextSelection
                                size="small"
                                style={{ width: 200 }}
                                data={params.show === "spu" ? listedSPU : listedOptions}
                                defaultValue={params.status}
                                onChange={(e) => dispatch(setProductParam({ status: e.target.value, page: 1 }))}
                            />
                            <TextField
                                size="small"
                                variant="outlined"
                                style={{ width: 400 }}
                                placeholder="Search by SKU ID, product name, or brand"
                                onChange={handleSearch}
                                value={value}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Icon style={{ textAlign: "center" }}>
                                                <img style={{ height: "100%" }} src="/assets/icon/search-outline.svg" />
                                            </Icon>
                                        </InputAdornment>
                                    ),
                                    endAdornment: value && (
                                        <InputAdornment position="end">
                                            <CloseIcon
                                                fontSize="small"
                                                onClick={handleClear}
                                                style={{ cursor: "pointer" }}
                                            />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                        <div className="show">
                            <TextSelection
                                size="small"
                                data={skuOption}
                                defaultValue={params.show}
                                onChange={(e) => dispatch(setProductParam({ show: e.target.value, page: 1 }))}
                                style={{ width: 200 }}
                            />
                        </div>
                    </Row>
                    <Table data={productData?.products || []} loading={productLoading} sort={params}>
                        <Topbar>
                            <Topleft>
                                <Checkbox
                                    color="primary"
                                    size="small"
                                    checked={
                                        checkedProducts.length === Math.min(productData.total_products, params.size) &&
                                        checkedProducts.length !== 0
                                    }
                                    onChange={(_, isChecked) => {
                                        dispatch(
                                            setProductChecklist(
                                                productData.products.map((item) => item.goods_id),
                                                isChecked,
                                                "list",
                                            ),
                                        );
                                    }}
                                />
                                <div>{checkedProducts.length || 0} Selected</div>
                                {params.show === "sku" ? (
                                    <>
                                        {checkedProducts.length > 0 && (
                                            <CustomButton
                                                onClick={() =>
                                                    setExportParam({
                                                        target: "merchant-product",
                                                        data: productData.products.filter((p) =>
                                                            checkedProducts.includes(p.goods_id),
                                                        ),
                                                    })
                                                }
                                            >
                                                Export Selected
                                            </CustomButton>
                                        )}
                                        {checkedProducts.length > 0 && params.status !== "all" && (
                                            <CustomButton
                                                ccolor="blue"
                                                cvariant="outlined"
                                                onClick={() => {
                                                    dispatch(
                                                        setPLStatus({
                                                            type: "sku",
                                                            action: params.status === "listed" ? "unlisted" : "listed",
                                                            ids: checkedProducts,
                                                        }),
                                                    );
                                                    dispatch(
                                                        setProductChecklist(
                                                            productData.products.map((item) => item.goods_id),
                                                            false,
                                                            "list",
                                                        ),
                                                    );
                                                }}
                                            >
                                                {params.status === "listed" ? "Unlist Selected" : "List Selected"}
                                            </CustomButton>
                                        )}
                                    </>
                                ) : (
                                    <>{checkedProducts.length !== 0 && <span>No actions available</span>}</>
                                )}
                            </Topleft>
                            <TopRight className="full">
                                <BulkUpdateButton
                                    cvariant="contained"
                                    ccolor="white"
                                    onClick={() => setShowUploadDialog(true)}
                                >
                                    Bulk update inventory & prices
                                </BulkUpdateButton>
                                <Divider orientation="vertical" flexItem />
                                {isExporting ? (
                                    <span>Exporting....</span>
                                ) : (
                                    <YohoButtonIcon
                                        src="/assets/icon/export.svg"
                                        width={120}
                                        height={35}
                                        cursor="pointer"
                                        text="Export All"
                                        onClick={() => setExportParam({ target: "merchant-product" })}
                                    />
                                )}
                            </TopRight>
                        </Topbar>
                        <Column type="custom" width={60} fixed render={CheckColumn} />
                        <Column
                            field={[
                                "image",
                                "product_name",
                                "sku",
                                "stock_qty",
                                "goods_id",
                                "yoho_stock_qty",
                                "discounts",
                            ]}
                            render={(props) => <Product onImageClick={onListProductImageClick} {...props} />}
                            fixed
                            width={370}
                            label="Name"
                            sortable={params.field === "goods_id"}
                            sort={params.sort}
                            sort_key="goods_id"
                            onSort={() =>
                                dispatch(
                                    setProductParam({
                                        field: "goods_id",
                                        sort: params.sort === "high_to_low" ? "low_to_high" : "high_to_low",
                                    }),
                                )
                            }
                        />
                        {params.show === "sku" && <Column field="status" render={Status} label="Status" />}
                        {params.show === "spu" && (
                            <Column
                                field="listed"
                                render={(e) => <SPUStatus data={e} type_data="listed" />}
                                label="Listed"
                            />
                        )}
                        {params.show === "spu" && (
                            <Column
                                field="unlisted"
                                render={(e) => <SPUStatus data={e} type_data="unlisted" />}
                                label="Unlisted"
                            />
                        )}
                        <Column field={["price_mbr", "price_mp", "price_vip"]} render={Price} label="Price" />
                        <Column field="cost" render={Cost} label="Cost" />
                        <Column
                            field="stock_qty"
                            label="Inventory"
                            sortable={params.field === "stock_qty"}
                            sort={params.sort}
                            sort_key="stock_qty"
                            onSort={() =>
                                dispatch(
                                    setProductParam({
                                        field: "stock_qty",
                                        sort: params.sort === "high_to_low" ? "low_to_high" : "high_to_low",
                                    }),
                                )
                            }
                        />
                        {isShowYohoQty && (
                            <Column
                                field="yoho_stock_qty"
                                label="YOHO Inventory"
                                sortable={params.field === "yoho_stock_qty"}
                                sort={params.sort}
                                sort_key="yoho_stock_qty"
                                onSort={() =>
                                    dispatch(
                                        setProductParam({
                                            field: "yoho_stock_qty",
                                            sort: params.sort === "high_to_low" ? "low_to_high" : "high_to_low",
                                        }),
                                    )
                                }
                            />
                        )}
                        <Column
                            field="sold"
                            label="Sold"
                            sortable={params.field === "sold"}
                            sort={params.sort}
                            sort_key="sold"
                            onSort={() =>
                                dispatch(
                                    setProductParam({
                                        field: "sold",
                                        sort: params.sort === "high_to_low" ? "low_to_high" : "high_to_low",
                                    }),
                                )
                            }
                        />
                        {params.show === "sku" && <Column field="gtin" label="GTIN" />}
                        <Column
                            type="custom"
                            width={150}
                            render={({ ...p }) => <ProductActions showing={params.show} {...p} />}
                        />
                    </Table>
                    <Pagination>
                        <div>
                            <YHPagination
                                size={productData?.total_pages}
                                action={(e) => dispatch(setProductParam({ page: e }))}
                                page={params.page}
                            />
                        </div>
                        <div>
                            <UncontrolledNormalSelect
                                variant="standard"
                                data={showOptions}
                                name="size"
                                prefix="Show"
                                onChange={(e) => dispatch(setProductParam(e))}
                                value={params.size}
                            />
                            <FormText>
                                <p className="subtitle2">Products out of {productData?.total_products}</p>
                            </FormText>
                        </div>
                    </Pagination>
                </ProductContainer>
                <Dialog
                    open={showUploadDialog}
                    onClose={closeUploadDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <StyledDialogTitle id="alert-dialog-title">Bulk update inventory and prices</StyledDialogTitle>
                    <DialogContent>
                        <StyledDialogContentText id="alert-dialog-description">
                            <div>
                                Please select the file you would like to import. Note that, only the following values
                                will be updated.
                            </div>
                            <div>
                                <ul style={{ padding: "0px 20px" }}>
                                    <li>VIP price</li>
                                    <li>Member price</li>
                                    <li>Market price</li>
                                    <li>Inventory</li>
                                </ul>
                            </div>
                            <div>
                                <StyledNoteTitle>Note:</StyledNoteTitle>
                                <StyledNoteContent>
                                    To obtain the template file for bulk update, please download it from the export
                                    button{" "}
                                    <img
                                        src="/assets/icon/export_grey.svg"
                                        width={20}
                                        style={{ verticalAlign: "text-top", padding: "2px" }}
                                    />{" "}
                                    located at top right of product list table.
                                </StyledNoteContent>
                            </div>
                        </StyledDialogContentText>
                        {uploadState === "error" ? (
                            <ErrorBox>
                                <ErrorTitle>
                                    <img
                                        src="/assets/icon/danger.svg"
                                        width={20}
                                        style={{ verticalAlign: "text-top", paddingRight: "4px" }}
                                    />
                                    Updates failed
                                </ErrorTitle>
                                <ErrorContent>{`There were problems importing the updates, ${errorMessage}. Please review the file and try again.`}</ErrorContent>
                            </ErrorBox>
                        ) : (
                            <></>
                        )}
                    </DialogContent>
                    <StyledDialogActions>
                        {!isUploadLoading ? (
                            <>
                                <FormButton onClick={closeUploadDialog} cvariant="outlined" width={147}>
                                    Cancel
                                </FormButton>
                                <YohoProductFileUpload
                                    name="product_file"
                                    className="half"
                                    setUploadState={setUploadState}
                                    setIsUploadLoading={setIsUploadLoading}
                                    setErrorMessage={setErrorMessage}
                                />
                            </>
                        ) : (
                            <CircularProgress />
                        )}
                    </StyledDialogActions>
                </Dialog>
            </Container>
        )
    );
};
