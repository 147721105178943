import { styled } from "@material-ui/core";

const Column = styled("div")({
    display: "flex",
    flexDirection: "column",
});

const ApplicationDetails = ({
    application_id,
    company_name_chinese,
    company_name_english,
    store_name_chinese,
    store_name_english,
}) => {
    return (
        <Column>
            <span>
                Application# <strong>{application_id}</strong>
            </span>
            <span>{company_name_chinese}</span>
            <span>{company_name_english}</span>
            <span>({store_name_chinese}) </span>
            <span>({store_name_english})</span>
        </Column>
    );
};

export default ApplicationDetails;
