import { Icon, InputAdornment, TextField } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Container from "components/MerchantCentre/Container";
import Content from "components/MerchantCentre/Content";
import Header from "components/MerchantCentre/Header";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setApplicationParam } from "service/actions/admin";
import { useGetApplicationList } from "./hooks";
import List from "./List";

const AdministrationConsole = () => {
    const filter = useSelector((state) => state.admin.param);
    const dispatch = useDispatch();
    const [tab, setTab] = useState("pending");
    const [value, setValue] = useState("");
    const [search, setSearch] = useState(null);
    const hasMounted = useRef(false);
    const { permissions = [] } = useSelector((state) => state.profile);

    const handleSearch = (e) => {
        setValue(e.target.value);
        setSearch(e.target.value);
    };

    const handleClear = () => {
        setValue("");
        setSearch(null);
    };

    useEffect(() => {
        if (!hasMounted.current) {
            // Skip effect for first render
            hasMounted.current = true;
            return;
        }

        dispatch(
            setApplicationParam({
                size: 30,
                status: tab,
                page: 1,
            }),
        );
    }, [tab]);

    const { isLoading, data: applicationData } = useGetApplicationList({ ...filter, search });

    const menuContent = [];
    if (!isLoading) {
        const itemCount = applicationData.result.total_items;
        menuContent.push({ name: "pending", count: itemCount.pending });
        if (permissions.includes("mc_application_edit")) {
            menuContent.push(
                { name: "approved", count: itemCount.approved },
                { name: "declined", count: itemCount.declined },
            );
        }
    }

    return (
        <Container>
            <div className="main">
                <Header menu={menuContent} active={tab} setActive={setTab} />
                <TextField
                    size="small"
                    variant="outlined"
                    style={{ width: 470, marginTop: 20 }}
                    placeholder="Search by company name, store name or application id"
                    value={value}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Icon style={{ textAlign: "center" }}>
                                    <img style={{ height: "100%" }} src="/assets/icon/search-outline.svg" />
                                </Icon>
                            </InputAdornment>
                        ),
                        endAdornment: value && (
                            <InputAdornment position="end">
                                <CloseIcon fontSize="small" onClick={handleClear} style={{ cursor: "pointer" }} />
                            </InputAdornment>
                        ),
                    }}
                    onKeyUp={handleSearch}
                    onChange={handleSearch}
                />
                <Content>
                    <List data={applicationData?.result} isLoading={isLoading} currentTab={tab} />
                </Content>
            </div>
        </Container>
    );
};

export default AdministrationConsole;
