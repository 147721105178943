import React from "react";
import ReactDOM from "react-dom";
import "./scss/global.scss";

// tiny mce
import "tinymce/tinymce";
import "tinymce/icons/default";
import "tinymce/themes/silver";
import "tinymce/plugins/paste";
import "tinymce/plugins/link";
import "tinymce/plugins/image";
import "tinymce/plugins/advlist";
import "tinymce/plugins/table";
import "tinymce/skins/ui/oxide/skin.min.css";
import "tinymce/skins/ui/oxide/content.min.css";

import * as serviceWorker from "./serviceWorker";
import { store, persistor } from "service/store";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import Routes from "pages/_routers";
import history from "service/history";
import { yoho } from "helper/yoho";
import { PersistGate } from "redux-persist/integration/react";
import CustomizedSnackbars from "components/General/Snackbar";
import { QueryClient, QueryClientProvider } from "react-query";

const theme = createMuiTheme({
    palette: {
        alternateTextColor: "#000",
        primary: {
            main: "#00acee",
        },
        secondary: {
            main: "#edf2f9",
        },
        text: {
            primary: "#333",
            fontFamily: ["Poppins", "sans-serif"].join(","),
        },
        background: {
            paper: "#fff",
            default: "#edf2f9",
            grey: "#e7e7e7",
            lightBlue: "#f9fafd",
            purple: "#393c64",
            leftNav: "#2c333a",
            yohoblue: "#00acee",
            greenBlue: "#0076A1",
        },
    },
    typography: {
        fontFamily: ["Poppins", "sans-serif"].join(","),
        body1: {
            display: "flex",
            fontSize: 14,
            fontFamily: ["Poppins", "sans-serif"].join(","),
        },
    },
    screenWidth: window.innerWidth,
    yoho: yoho,
});

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            cacheTime: 0,
        },
    },
});

ReactDOM.render(
    <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
            <Provider store={store}>
                <PersistGate persistor={persistor}>
                    <BrowserRouter history={history}>
                        <Routes />
                    </BrowserRouter>
                    <CustomizedSnackbars />
                </PersistGate>
            </Provider>
        </ThemeProvider>
    </QueryClientProvider>,
    document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// REMARKS: work around fix hot reload https://github.com/facebook/create-react-app/issues/9904#issuecomment-1265102578
if (module.hot) {
    module.hot.accept();
}
