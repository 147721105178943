import { styled } from "@material-ui/core";
import api from "service/apis/config";

const UploadButtonContainer = styled("div")({});

const UploadButton = styled("div")({
    display: "flex",
    gap: "10px",
});

const Button = styled("label")({
    width: "147px",
    height: "52px",
    borderRadius: "26px",
    border: "1px solid #00ACEE",
    display: "flex",
    alignItems: "center",
    gap: "5px",
    justifyContent: "center",
    backgroundColor: "#00ACEE",
    color: "#FFFFFF",
    cursor: "pointer",
    transition: "all 0.3s ease",
    "&:hover": {
        backgroundColor: "#0076A1",
    },
    "&:active": {
        backgroundColor: "#00ACEE",
        color: "white",
    },
    "& > input": {
        display: "none",
    },
});

const UploadText = styled("p")({
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "24px",
});

const ProductFileUpload = ({ className, name, setIsUploadLoading, setUploadState, setErrorMessage }) => {
    const handleSubmit = async (event) => {
        setIsUploadLoading(true);
        event.preventDefault();
        const formData = new FormData();
        formData.append("product_file", event.target.files[0]);
        const res = await api.post(`/merchant/products/upload-product`, formData, {
            headers: {
                "content-type": "multipart/form-data",
            },
        });
        if (res.ok) {
            setIsUploadLoading(false);
            setUploadState("success");
        } else {
            setIsUploadLoading(false);
            setUploadState("error");
            setErrorMessage(res.originalError);
        }
    };

    return (
        <UploadButtonContainer className={className}>
            <UploadButton>
                <Button htmlFor="productFileUpload" onChange={handleSubmit}>
                    <UploadText>Choose file</UploadText>
                    <input type="file" id="productFileUpload" name={name} accept=".xlsx,.xls" />
                </Button>
            </UploadButton>
        </UploadButtonContainer>
    );
};

export default ProductFileUpload;
