import { Chip, makeStyles, styled, TextField } from "@material-ui/core";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import usePopup from "hooks/usePopup";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteAddProductVariationTerms, setAddProductVariationTerms } from "service/actions/merchant";
import ProductVariationTermsPopup from "./popup/ProductVariationTermsPopup";
import AutoCompletePopper from "components/General/Popper";

const useStyles = makeStyles((theme) => ({
    option: {
        fontSize: 15,
        width: "100%",
    },
    chip: {
        backgroundColor: theme.yoho.md_color.blue[900],
        color: theme.yoho.color.white,
    },
    deleteIcon: {
        color: "unset",
    },
}));

const RenderContent = styled("div")(({ theme }) => ({
    width: "100%",
    display: "flex",
    gap: 7.5,
    "& .en": {
        color: theme.yoho.md_color.grey[800],
    },
    "& .zh": {
        color: theme.yoho.md_color.grey[500],
    },
}));

const filter = createFilterOptions();

const deleteIcon = (
    <svg className="MuiSvgIcon-root MuiChip-deleteIcon makeStyles-deleteIcon-83" viewBox="0 0 24 24">
        <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z" />
    </svg>
);

const RenderItem = ({ dataitem }, props) => {
    return dataitem.create ? (
        <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
            <span>{dataitem.value}</span>
        </div>
    ) : (
        <RenderContent>
            <span className="en">{dataitem?.attribute_item_name_english}</span>
            <span className="zh">{dataitem?.attribute_item_name_chinese}</span>
        </RenderContent>
    );
};

export default ({ className, label, attributeData, variant = "outlined" }) => {
    const ref = useRef(null);
    const classes = useStyles();
    const popup = usePopup();
    const dispatch = useDispatch();

    const variationAttributeItem = useSelector((state) => {
        const attributeItems = state.merchant.product.config.variation_attribute_item;
        return attributeItems.filter(({ attribute_id }) => attribute_id === attributeData.attribute_id);
    });

    const filterOption = (options, params, data) => {
        const existingItemIds = attributeData.items.map(({ attribute_item_id }) => attribute_item_id);
        const addableOptions = options?.filter((item) => !existingItemIds.includes(item.attribute_item_id));
        const filtered = filter(addableOptions, params);

        const addAttributeItem = {
            create: true,
            value: "+ Create New Attribute Item",
            attribute_item_name_chinese: "",
            attribute_item_name_english: "",
            attribute_id: attributeData.attribute_id,
        };

        return [...filtered, addAttributeItem];
    };

    return (
        <Autocomplete
            multiple
            filterSelectedOptions
            classes={{ option: classes.option }}
            PopperComponent={AutoCompletePopper}
            ref={ref}
            options={variationAttributeItem}
            value={attributeData.items}
            className={className}
            onChange={(_, values, status, event) => {
                if (status === "remove-option") {
                    const { attribute_id, attribute_item_id } = event.option;
                    dispatch(deleteAddProductVariationTerms(attribute_id, attribute_item_id));
                } else {
                    if (event.option.create) {
                        popup(ProductVariationTermsPopup, {
                            attributeId: attributeData.attribute_id,
                            action: (data) => dispatch(setAddProductVariationTerms(data)),
                        });
                    } else {
                        dispatch(setAddProductVariationTerms(event.option));
                    }
                }
            }}
            getOptionLabel={(option) => {
                return `${option?.attribute_item_name_english} ${option?.attribute_item_name_chinese}`;
            }}
            getOptionSelected={(option, value) => {
                return option == value;
            }}
            filterOptions={filterOption}
            renderOption={(op) => {
                return <RenderItem dataitem={op} />;
            }}
            renderInput={(params) => (
                <TextField {...params} variant={variant} label={label} placeholder="Variations Terms" />
            )}
            renderTags={(_, getTagProps) => {
                return attributeData?.items?.map((option, index) => (
                    <Chip
                        key={index}
                        classes={{ root: classes.chip, deleteIcon: classes.deleteIcon }}
                        deleteIcon={deleteIcon}
                        variant="outlined"
                        label={
                            `${option["attribute_item_name_english"]} ${option["attribute_item_name_chinese"]}` ||
                            option
                        }
                        {...getTagProps({ index })}
                    />
                ));
            }}
        />
    );
};
