import { all, spawn, call } from "redux-saga/effects";
import cookie from "js-cookie";

// import authentication from "./authentication"
import auth from "./authentication";
import admin from "./admin";
import site from "./sites";
import merchant from "./merchant";

function* rootSaga() {
    const sagas = [
        // authentication,
        auth,
        admin,
        merchant,
        site,
    ];

    yield all(
        sagas.map((saga) =>
            spawn(function* () {
                while (true) {
                    try {
                        yield call(saga);
                        break;
                    } catch (e) {
                        if (process?.env.NODE_ENV !== "production") {
                            console.log(e);
                        }
                    }
                }
            }),
        ),
    );
}

export default rootSaga;
